import React from "react";

export const Button = ({
  type,
  className,
  children,
  onClick,
}: {
  className?: string;
  type: "outlined" | "solid";
  children?: any;
  onClick?: () => void;
}) => {
  switch (type) {
    case "outlined":
      return (
        <button
          onClick={onClick}
          className={`min-w-[120px] text-primary hover:text-white border-primary hover:bg-primary transition-all text-[15px] border px-5 py-2 rounded-md ${className}`}
        >
          {children}
        </button>
      );
    case "solid":
      return (
        <button
          onClick={onClick}
          className={`min-w-[120px] text-white bg-primary hover:bg-[#08d17a] transition-all text-[15px] border border-primary hover:border-[##08d17a] px-5 py-2 rounded-md ${className}`}
        >
          {children}
        </button>
      );

    // default:
    //   return <button className={`${className}`}>{children}</button>;
  }
};

export const Button2 = ({
  id,
  type,
  className,
  children,
}: {
  id: "submit";
  className?: string;
  type: "outlined" | "solid";
  children?: any;
}) => {
  switch (type) {
    case "outlined":
      return (
        <button
          className={`min-w-[120px] text-primary hover:text-white border-primary hover:bg-primary transition-all text-[15px] border px-5 py-2 rounded-md ${className}`}
        >
          {children}
        </button>
      );
    case "solid":
      return (
        <button
          className={`min-w-[120px] text-white bg-primary hover:bg-[#08d17a] transition-all text-[15px] border border-primary hover:border-[##08d17a] px-5 py-2 rounded-md ${className}`}
        >
          {children}
        </button>
      );

    // default:
    //   return <button className={`${className}`}>{children}</button>;
  }
};
