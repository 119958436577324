import React from "react";
import HomeLayout from "../layouts/homeLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "../components/button";
import { features } from "../assets/data/features";

const Feature = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState<{
    name: string;
    content: string[];
    image: any;
  } | null>(null);

  React.useEffect(() => {
    if (id) {
      const newData = features.find((item, idx) => idx === Number(id));
      if (newData) {
        setData(newData);
      } else {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  }, [id]);
  return (
    <HomeLayout>
      {/* HERO START */}
      <div className="w-full bg-gradient-to-br from-primary to-black h-[50vh] relative">
        <div className="w-full h-full bg-[#000000e5] absolute top-0 left-0 flex flex-col items-center justify-center px-3">
          <h1 className="text-[33px] max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
            {data?.name}
          </h1>
          <div className="h-[80px]" />
        </div>
        <div className="section-bottom" />
      </div>
      <div className="w-full bg-main relative">
        <div className="container mx-auto h-full px-3 flex flex-col lg:flex-row items-start justify-between ">
          <div className="w-full lg:w-[65%]">
            {data?.content.map((item, idx) => (
              <p
                key={idx}
                className="text-[16px] mx-auto  mt-4 font-normal text-[#373435] mb-10 text-left tracking-[0.56px] leading-[28px]"
              >
                {item}
              </p>
            ))}
          </div>
          <div className="w-full lg:w-[30%] mt-10 lg:mt-0">
            <div className="w-full rounded-md overflow-hidden">
              <img
                src={data?.image || ""}
                className="h-auto w-[100%] mb-10 rounded-md justify-center"
                alt=""
                style={{ boxShadow: "0 2px 48px 0 rgba(0, 0, 0, 0.13)" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white relative ">
        <div className="section-top" />
        <div className="section-bottom" />
        <div className="grid justify-center items-center grid-cols-1 md:grid-cols-2 h-full">
          <div className="bg-home-contact w-full min-h-[450px] relative flex flex-col px-3 justify-center items-center">
            <div className="block md:hidden h-[70px]" />
            <p className="text-[13px] z-10 max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
              GET IN TOUCH
            </p>
            <h1 className="text-[18px] z-10 max-w-[450px] font-bold text-white mb-3 text-center tracking-[1.12px] leading-[30px]">
              You can contact us for purchase, installation and customizations.
            </h1>
            <div className="flex items-center z-10">
              <Link to="/contact">
                <Button type="outlined">Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="bg-home-doc w-full min-h-[450px] relative flex flex-col px-3 justify-center items-center">
            <p className="text-[13px] z-10 max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
              API & SDK
            </p>
            <h1 className="text-[18px] z-10 max-w-[450px] font-bold text-white mb-3 text-center tracking-[1.12px] leading-[30px]">
              You can find detailed information about our developer APIs here.
            </h1>
            <div className="flex items-center z-10">
              <Button type="outlined">Documentation</Button>
            </div>
            <div className="block md:hidden h-[100px]" />
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Feature;
