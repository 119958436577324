import React from "react";
import { ReactComponent as Logo } from "../assets/images/logo.svg";
import { Button } from "../components/button";
import { Link, NavLink } from "react-router-dom";
import { CgMenuLeft } from "react-icons/cg";
import { Drawer } from "@mui/joy";
import { HashLink } from "react-router-hash-link";
import {
  FaFacebookF,
  FaGithub,
  FaGooglePlusG,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import { FaAngleRight } from "react-icons/fa6";

const HomeLayout = ({
  children,
}: {
  children: React.ReactNode[] | React.ReactNode;
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  return (
    <div className="w-full h-full bg-white">
      <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
        {/* <ModalClose /> */}
        <div className="w-full h-full">
          <div className="px-4">
            <Logo />
          </div>
          <ul className="flex flex-col mt-7">
            <li className="mx-3 text-[15px] mb-4 text-center">
              <NavLink
                to={"/"}
                className={"hover:text-primary text-[#373435] transition-all"}
              >
                Home
              </NavLink>
            </li>
            <li className="mx-3 text-[15px] mb-4 text-center">
              <NavLink
                to={"/about"}
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                About
              </NavLink>
            </li>
            <li className="mx-3 text-[15px] mb-4 text-center">
              <HashLink
                smooth
                to="/#features"
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                Features
              </HashLink>
            </li>
            <li className="mx-3 text-[15px] mb-4 text-center">
              <HashLink
                smooth
                to="/#pricing"
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                Pricing
              </HashLink>
            </li>
            <li className="mx-3 text-[15px] mb-4 text-center">
              <HashLink
                smooth
                to="/#faq"
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                FAQ
              </HashLink>
            </li>
            <li className="mx-3 text-[15px] mb-4 text-center">
              <Link to="/contact">
                <Button type="outlined">Contact Us</Button>
              </Link>
            </li>
          </ul>
        </div>
      </Drawer>
      <div className="w-full shadow-xl bg-white sticky z-50 top-0 left-0">
        <div className="container mx-auto py-4 flex justify-between items-center  px-3">
          <Link to="/">
            <Logo />
          </Link>
          <ul className="hidden lg:flex items-center justify-between">
            <li className="mx-3 text-[15px]">
              <NavLink
                to={"/"}
                className={"hover:text-primary text-[#373435] transition-all"}
              >
                Home
              </NavLink>
            </li>
            <li className="mx-3 text-[15px]">
              <NavLink
                to={"/about"}
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                About
              </NavLink>
            </li>
            <li className="mx-3 text-[15px]">
              <HashLink
                smooth
                to="/#features"
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                Features
              </HashLink>
            </li>

            <li className="mx-3 text-[15px]">
              <HashLink
                smooth
                to="/#faq"
                className={"text-[#373435] hover:text-primary transition-all"}
              >
                FAQ
              </HashLink>
            </li>

            <li className="mx-3 text-[15px]">
              <Link to="/contact">
                <Button type="outlined">Contact Us</Button>
              </Link>
            </li>
          </ul>
          <CgMenuLeft
            size={30}
            className="lg:hidden"
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      {children}
      <footer className="w-full bg-main relative">
        <div className="container mx-auto h-full py-2 px-3 w-full flex flex-wrap flex-row justify-between items-start">
          <div className="w-[100%] lg:w-[35%] mb-4">
            <Link to="/#"><Logo /> </Link>

            <p className="my-0 text-[#373435e5]">
              &#169; 2024 Akawo Synergy Limited 
            </p>

            <p className="my-4 text-[#373435e5]">
              Akawo Synergy, a top financial services provider, primarily serves
              financial technology companies, with its banking services provided
              by licensed partner banks.
            </p>
            <div className="flex flex-row">
              <a href="/#" className="mr-3">
                <FaFacebookF className="text-[20px] text-[#373435e5]" />
              </a>
              <a href="/#" className="mr-3">
                <FaTwitter className="text-[20px] text-[#373435e5]" />
              </a>
              <a href="/#" className="mr-3">
                <FaLinkedinIn className="text-[20px] text-[#373435e5]" />
              </a>
              <a href="/#" className="mr-3">
                <FaInstagram className="text-[20px] text-[#373435e5]" />
              </a>
              
            </div>
          </div>
          <div className="w-[50%] sm:w-[30%] lg:w-[15%] mb-4">
            <h2 className="text-[#373435] mb-4 text-[20px]">Helpful Links</h2>

            <Link to="/about" className="flex items-center text-[#373435e5]">
              <FaAngleRight />
              <p className="ml-2">About us</p>
            </Link>
            <HashLink
              smooth
              to="/#features"
              className="flex items-center text-[#373435e5]"
            >
              <FaAngleRight />
              <p className="ml-2">Features</p>
            </HashLink>

            <HashLink
              smooth
              to="/#faq"
              className="flex items-center text-[#373435e5]"
            >
              <FaAngleRight />
              <p className="ml-2">FAQ</p>
            </HashLink>
          </div>

          <div className="w-[50%] sm:w-[30%] lg:w-[15%] mb-4">
            <h2 className="text-[#373435] mb-4 text-[20px]">Products</h2>

            <Link to="/puplar.com" className="flex items-center text-[#373435e5]">
              <FaAngleRight />
              <p className="ml-2">Usepuplar</p>
            </Link>
            

            
          </div>
          <div className="w-[50%] sm:w-[30%] lg:w-[15%] mb-4">
            <h2 className="text-[#373435] mb-4 text-[20px]">Support</h2>

            <Link to="/privacy" className="flex items-center text-[#373435e5]">
              <FaAngleRight />
              <p className="ml-2">Privacy Policy</p>
            </Link>
            {/* <Link to="/about" className="flex items-center text-[#373435e5]">
              <FaAngleRight />
              <p className="ml-2">Terms of Use</p>
            </Link> */}

            <Link to="/contact" className="flex items-center text-[#373435e5]">
              <FaAngleRight />
              <p className="ml-2">Contact</p>
            </Link>
          </div>
          <div className="w-[100%] sm:w-[30%] lg:w-[15%] mb-4">
            <h2 className="text-[#373435] mb-4 text-[20px]">Contact Us</h2>

            <div className="flex items-center text-[#2348164659672]">
              <p className="ml-2">New GRA 500101, Port Harcourt, Nigeria</p>
            </div>
            <a
              href="tel:+2347041528380"
              className="flex items-center text-[#2348164659672]"
            >
              <p className="ml-2">Phone: (234) 8164659672</p>
            </a>
            <a
              href="mailto:help@akawo.me"
              className="flex items-center text-[#2348164659672]"
            >
              <p className="ml-2">Email:help@akawo.me</p>
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomeLayout;
