import React from "react";
import HomeLayout from "../layouts/homeLayout";
import { ContactCard } from "../components/card";
import { FaLocationArrow, FaPhoneAlt } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Button } from "../components/button";

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};
const center = {
  lat: 4.815909, // default latitude
  lng: 7.0075182, // default longitude
};

const Contact = () => {
  const [button, setButton] = React.useState<string>("SEND");
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [subject, setSubject] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const [success, setSuccess] = React.useState("");
  const [error, setError] = React.useState("");
  const { isLoaded, loadError: _loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyC6yqP8_qWQsmhyqkSrAgTm7CUQ6yHwzRY",
    libraries: ["places"],
  });

  const sendMail = () => {
    if (!name || !email || !subject || !message) {
      setError("Please provide all inputs");
      return;
    }
    if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      setError("Invalid email address");
      return;
    }
    setError("");
    setSuccess("");
    setButton("Loading...");
    emailjs
      .send(
        "service_jle328q",
        "template_mfgmm1t",
        { name, email, subject, message },
        {
          publicKey: "H0pvftW9RjeRdaiWa",
        }
      )
      .then(
        () => {
          setButton("SEND");
          setSuccess("Sent Successfully!");
          setName("");
          setEmail("");
          setSubject("");
          setMessage("");
        },
        (error) => {
          if (error instanceof EmailJSResponseStatus) {
            setError(error.text);
          }

          console.log("ERROR", error);
        }
      );
  };

  return (
    <HomeLayout>
      {/* HERO START */}
      <div className="w-full bg-gradient-to-br from-[#03D17A] to-black h-[50vh] relative">
        <div className="w-full h-full bg-[#000000e5] absolute top-0 left-0 flex flex-col items-center justify-center px-3">
          <h1 className="text-[33px] max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
            Contact Us
          </h1>
          <div className="h-[80px]" />
        </div>
        <div className="section-bottom" />
      </div>
      <div className="w-full bg-main relative">
        <div className="container mx-auto h-full px-3 pt-[40px]">
          <div className="w-full mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-6 gap-y-12 pb-5">
            <ContactCard icon={<FaLocationArrow color="#fff" size={20} />}>
              New GRA 500101, Port Harcourt, Rivers, Nigeria
            </ContactCard>
            <ContactCard icon={<FaPhoneAlt color="#fff" size={20} />}>
              <a href="tel:+2348164659672" className="mb-1">
                (234) 8164659672
              </a>
              <br />
              <a href="tel:+2348131569210">(234) 8131569210</a>
            </ContactCard>
            <ContactCard icon={<MdMailOutline color="#fff" size={23} />}>
              <a href="mailto:help@akawo.me" className="mb-1">
                help@akawo.me
              </a>
              <br />
              <a href="mailto:admin@akawo.me">admin@akawo.me</a>
            </ContactCard>
          </div>
        </div>
      </div>
      <div className="w-full bg-white relative h-[450px]">
        <div className="section-top" />
        <div className="section-bottom" />
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={{ ...center }}
          >
            <Marker position={{ ...center }} />
          </GoogleMap>
        )}
      </div>
      <div className="w-full bg-main relative pb-20">
        <div className="container mx-auto h-full px-3 flex flex-col md:flex-row items-start justify-between">
          <div className="w-[100%] lg:w-[32%]">
            <h1 className="text-[22px] font-normal text-[#373435] text-left tracking-[1.4px] mb-3 leading-[42px]">
              Get in touch
            </h1>
            <p className="text-[16px] font-normal text-[#373435] mb-2 text-left tracking-[0.56px] leading-[28px]">
              Let's know how best we can serve you
            </p>
          </div>
          <div className="w-[100%] lg:w-[62%] flex flex-row flex-wrap justify-between">
            {error && (
              <p className="text-[16px] w-full font-normal text-red-500 mb-2 text-left tracking-[0.56px] leading-[28px]">
                {error}
              </p>
            )}
            {success && (
              <p className="text-[16px] w-full font-normal text-green-500 mb-2 text-left tracking-[0.56px] leading-[28px]">
                {success}
              </p>
            )}
            <input
              className="w-[100%] sm:w-[47%] px-3 py-2 bg-white border border-gray-200 rounded-md mb-3"
              placeholder="Name, Surname"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="w-[100%] sm:w-[47%] px-3 py-2 bg-white border border-gray-200 rounded-md mb-3"
              placeholder="Email Address"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              className="w-[100%] px-3 py-2 bg-white border border-gray-200 rounded-md mb-3"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            />
            <textarea
              className="w-[100%] px-3 py-2 bg-white border border-gray-200 rounded-md mb-5"
              cols={5}
              rows={5}
              placeholder="Your message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
            <Button
              type="solid"
              onClick={() => !Boolean(button === "Loading...") && sendMail()}
            >
              {button}
            </Button>
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Contact;
