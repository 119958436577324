import React from "react";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

export const HelpCard = ({
  title,
  children,
  icon,
}: {
  icon: React.ReactNode;
  children: any;
  title: any;
}) => {
  return (
    <div
      className="w-full px-4 pb-4 shadow-md rounded-lg bg-white group transition-all"
      style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
    >
      <div className="w-[50px] h-[50px] bg-[#373435] flex justify-center items-center font-medium mx-auto relative top-[-25px] rounded-md">
        {icon}
      </div>
      <h1 className="text-[16px] text-center tracking-[1.4px] group-hover:text-[#373435] mb-3 transition-all">
        {title}
      </h1>
      <p className="text-[14px] text-center text-[#373435] group-hover:text-[#373435] tracking-[1.4px] transition-all">
        {children}
      </p>
    </div>
  );
};

export const ContactCard = ({
  children,
  icon,
}: {
  icon: React.ReactNode;
  children: any;
}) => {
  return (
    <div
      className="w-full px-4 pb-4 shadow-md rounded-lg bg-white group transition-all"
      style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
    >
      <div className="w-[50px] h-[50px] bg-[#373435] flex justify-center items-center font-medium mx-auto relative top-[-25px] rounded-full">
        {icon}
      </div>
      <p className="text-[16px] text-center text-[#373435] group-hover:text-[#373435] tracking-[1.4px] transition-all">
        {children}
      </p>
    </div>
  );
};

export const AccordionCard = ({ text, idx }: { text: string, idx: number }) => {
  return (
    <Link to={`/feature/${idx}`}>
      <div
        className="w-full px-3 py-3 flex items-center rounded-md group hover:bg-primary transition-all bg-white"
        style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
      >
        <FaAngleRight
          size={20}
          className="text-[#373435] group-hover:text-white transition-all"
        />
        <p className="text-[14px] text-left ml-4 text-[#373435]  group-hover:text-white tracking-[1.4px] transition-all">
          {text}
        </p>
      </div>
    </Link>
  );
};

export const PricingCard = ({
  children,
  title,
  color = "primary",
  amount,
}: {
  title: string;
  color?: "primary" | "light";
  amount: number;
  children: any;
}) => {
  return (
    <div
      className="w-full pb-4 shadow-md rounded-lg bg-white group transition-all"
      style={{ boxShadow: "0px 0px 10px rgba(0,0,0,0.1)" }}
    >
      <div className="w-[150px] h-[40px] bg-primary flex justify-center items-center font-medium mx-auto relative top-[-25px] rounded-md">
        <p className="text-[15px] text-white">{title}</p>
      </div>
      <div
        className={`w-full ${
          color === "light" ? "bg-light" : "bg-primary"
        } py-3 flex justify-center items-center mb-3`}
      >
        <span
          className={`font-semibold text-[20px] ${
            color === "primary" ? "text-[#fff]" : "text-[#373435]"
          } relative top-[-7px]`}
        >
          $
        </span>
        <span
          className={`font-bold text-[34px] ${
            color === "primary" ? "text-[#fff]" : "text-[#373435]"
          } tracking-[2.12px] mx-1`}
        >
          {amount}
        </span>
        <span
          className={`font-bold text-[14px] ${
            color === "primary" ? "text-[#fff]" : "text-[#373435]"
          } relative bottom-[-7px] tracking-[0.88px]`}
        >
          /Month
        </span>
      </div>
      <div className="w-full px-3 flex flex-col items-center">{children}</div>
    </div>
  );
};
