import virtual_account from "../../assets/images/virtual_account.png";
import issuing from "../../assets/images/issuing.png";
import accept_payment from "../../assets/images/accept_payment.png";
import foreign_exchange from "../../assets/images/fx.png";

export const features = [
  {
    name: "Virtual Account",
    content: [
      `Create a virtual account number within 5 minutes for businesses, sole proprietors, and individuals with unique routing numbers and account details to securely receive payments and protect sensitive financial information. Akawo Synergy offers a seamless process for generating virtual account numbers quickly and efficiently, ensuring convenience and security for all users.`,
      
    ],
    image: virtual_account,
  },
  {
    name: "Issuing",
    content: [
      `Through our card issuing services, businesses can create customized prepaid and debit cards tailored to their specific needs. With the ability to have a monthly spending of $10,000 globally, our card issuing APIs can help you expand your business. Cards can be used for employee incentives, customer rewards, or even as a promotional tool to increase brand visibility. Additionally, our platform allows for easy tracking and management of card usage and funds.`,
    ],
    image: issuing,
  },
  {
    name: "Accept Payment",
    content: [
      `Provide the option to accept payments or withdraw money. You can charge for these services. You can also set up automatic transfers, view transaction history, and receive notifications for account activity. Additionally, you have the option to link multiple bank accounts for added convenience. You have flexibility, control, and real-time data on every transaction.`,
    ],
    image: accept_payment,
  },

  {
    name: "Foreign Exchange",
    content: [
      `Businesses may convert money between currencies and access liquidity in that currency pair at the best rates. Businesses can also keep USD and use local currencies to pay staff, merchants, and other parties as needed using our FX APIs.`,
    ],
    image: foreign_exchange,
  },
];
