import React from "react";
import HomeLayout from "../layouts/homeLayout";
import about_one from "../assets/images/coo.png";
// import about_two from "../assets/images/group.png";
import { Button } from "../components/button";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <HomeLayout>
      {/* HERO START */}
      <div className="w-full  bg-gradient-to-br from-[#03D17A] to-black h-[50vh] relative">
        <div className="w-full h-full bg-[#000000e5] absolute top-0 left-0 flex flex-col items-center justify-center px-3">
          <h1 className="text-[33px] max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
            About Us
          </h1>
          <div className="h-[80px]" />
        </div>
        <div className="section-bottom" />
      </div>
      <div className="w-full bg-main relative ">
        <div className="container mx-auto h-full px-3">
          <h1 className="text-[30px] mx-auto font-normal text-[#373435] text-center tracking-[1.4px] mb-3 leading-[42px]">
            Akawo Synergy Limited
          </h1>
          <p className="text-[16px] mx-auto max-w-[800px] font-normal text-[#373435] mb-10 text-center tracking-[0.56px] leading-[28px]">
            provider of financial services, specializing in payment gateway
            solutions, virtual accounts, card issuing, and foreign exchange (FX)
            services. With a commitment to innovation, reliability, and customer
            satisfaction, we strive to exceed the expectations of our clients
            and partners.
          </p>
          <div className="relative pt-[10px] h-[400px] max-w-[400px] mx-auto ">
            <img
              src={about_one}
              className="h-auto max-w-[100%] relative mb-[30px] mx-auto md:mb-0 md:absolute left-0 z-[2] rounded-md overflow-hidden"
              alt=""
              style={{ boxShadow: "0 2px 48px 0 rgba(0, 0, 0, 0.13)" }}
            />
            {/* <img
              style={{ boxShadow: "0 2px 48px 0 rgba(0, 0, 0, 0.13)" }}
              src={about_two}
              className="h-auto max-w-[100%] rounded-md relative md:mb-0 md:absolute top-0 md:top-12 right-0 z-[1] flex justify-center md:justify-end"
              alt=""
            /> */}
            
          </div>
          
          <div className="pt-[100px] md:pt-[50px]">
            <p className="text-[16px] mx-auto max-w-[800px] font-normal text-[#373435] mb-4 text-center tracking-[0.56px] leading-[28px]">
              Our goal is to transform how organizations handle the financial
              environment, not merely provide financial services. We're enabling
              businesses to realize their full potential and prosper in the
              digital economy with our fintech solutions—we're not just offering
              a service."
            </p>
            <h2 className="text-[20px] mx-auto font-medium italic text-[#373435] pb-10 text-center tracking-[0.56px] leading-[28px]">
              Precious Leo
            </h2>
          </div>
        </div>
      </div>
      <div className="w-full bg-white relative ">
        <div className="section-top" />
        <div className="section-bottom" />
        <div className="grid justify-center items-center grid-cols-1 md:grid-cols-2 h-full">
          <div className="bg-home-contact w-full min-h-[450px] relative flex flex-col px-3 justify-center items-center">
            <div className="block md:hidden h-[70px]" />
            <p className="text-[13px] z-10 max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
              GET IN TOUCH
            </p>
            <h1 className="text-[18px] z-10 max-w-[450px] font-bold text-white mb-3 text-center tracking-[1.12px] leading-[30px]">
              You can contact us for purchase, installation and customizations.
            </h1>
            <div className="flex items-center z-10">
              <Link to="/contact">
                <Button type="outlined">Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="bg-home-doc w-full min-h-[450px] relative flex flex-col px-3 justify-center items-center">
            <p className="text-[13px] z-10 max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
              API & SDK
            </p>
            <h1 className="text-[18px] z-10 max-w-[450px] font-bold text-white mb-3 text-center tracking-[1.12px] leading-[30px]">
              You can find detailed information about our developer APIs here.
            </h1>
            <div className="flex items-center z-10">
              <Button type="outlined">Documentation</Button>
            </div>
            <div className="block md:hidden h-[100px]" />
          </div>
        </div>
      </div>
    </HomeLayout>
  );
};

export default About;
