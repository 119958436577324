import React from "react";
import HomeLayout from "../layouts/homeLayout";
import { Button } from "../components/button";
import { HiBanknotes } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { FaDollarSign } from "react-icons/fa";
import { HiOutlineCreditCard } from "react-icons/hi2";
import { RiBankFill } from "react-icons/ri";
import keyboard from "../assets/images/growth2.gif";
// import integrate from "../assets/images/integrate.jpg";
import { AccordionCard, HelpCard } from "../components/card";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import { features } from "../assets/data/features";

const Home = () => {
  return (
    <HomeLayout>
      {/* HERO START */}
      <div className="w-full bg-gradient-to-br from-[#03D17A] to-black h-[100vh] relative">
        <div className="w-full h-full absolute top-0 left-0 flex flex-col items-center justify-center px-3">
          <h1 className="text-[30px] md:text-[40px] max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
            Unlock the <s>potential</s> magic &#127881; of seamless payment
          </h1>
          <h1 className="text-[16px] max-w-[700px] font-normal text-white mb-3 text-center tracking-[0.56px] leading-[28px]">
            Akawo Synergy, allowing you to integrate robust financial services
            seamlessly into your products.
          </h1>
          <div className="flex items-center">
            <Button type="solid">Start Now</Button>
            <div className="w-[30px]" />
            <Button type="outlined">View Docs</Button>
          </div>
          <div className="h-[80px]" />
        </div>

        <div className="section-bottom" id="features" />
      </div>
      {/* HERO END */}
      {/* KEY FEATURES START */}
      <div className="w-full bg-main relative">
        <div className="container mx-auto h-full px-3">
          <h1 className="text-[30px] mx-auto font-normal text-[#373435] text-center tracking-[1.4px] mb-3 leading-[42px]">
            Our Services
          </h1>
          <p className="text-[16px] mx-auto max-w-[600px] font-normal text-[#373435] mb-10 text-center tracking-[0.56px] leading-[28px]">
            Customizable virtual account solutions, personalized card issuance
            options, and competitive foreign exchange rates.
          </p>
          <div className="w-full mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-6 gap-y-12 pb-5">
            <HelpCard
              title="Virtual Account"
              icon={<HiBanknotes color="#fff" size={23} />}
            >
              Our virtual account services provide businesses with flexible and
              efficient financial management solutions.
            </HelpCard>
            <HelpCard
              title="Issuing"
              icon={<HiOutlineCreditCard color="#fff" size={23} />}
            >
              Through our card issuing services, businesses can create
              customized prepaid, debit cards tailored to their specific needs.
            </HelpCard>
            <HelpCard
              title="Accept Payment"
              icon={<RiBankFill color="#fff" size={23} />}
            >
              Our advanced payment gateway solutions enable businesses to
              securely process online transactions, accept payments from
              multiple channels, and improve payment acceptance rates.
            </HelpCard>
            <HelpCard title="FX" icon={<FaDollarSign color="#fff" size={23} />}>
              Our comprehensive FX services help businesses mitigate currency
              risk, optimize exchange rates, and streamline international
              payments.
            </HelpCard>
          </div>
        </div>
      </div>
      {/* KEY FEATURES ENDS */}
      <div className="w-full bg-white relative">
        <div className="container mx-auto h-full px-3 pt-40 pb-36 w-full">
          <div className="grid  justify-center items-center grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-10 h-full">
            <div className="w-full h-full flex items-center justify-center">
              <img
                src={keyboard}
                alt=""
                className="w-full h-full md:max-h-[300px] object-contain"
              />
            </div>
            <div className="w-full h-full flex flex-col justify-center">
              <h1 className="text-[30px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-3 leading-[42px]">
                Know More &#128176;
              </h1>
              <p className="text-[16px] mx-auto max-w-[600px] font-normal text-[#373435] mb-7 text-left tracking-[0.56px] leading-[28px]">
                Accelerate your growth and maximize profits.
              </p>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                {features.map((item, idx) => (
                  <AccordionCard idx={idx} text={item.name} key={idx} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="section-top" />
        <div className="section-bottom" />
      </div>
      
      <div className="w-full bg-white relative ">
        <div className="section-top" />
        <div className="section-bottom" />
        <div className="grid justify-center items-center grid-cols-1 md:grid-cols-2 h-full">
          <div className="bg-home-contact w-full min-h-[450px] relative flex flex-col px-3 justify-center items-center">
            <div className="block md:hidden h-[70px]" />
            <p className="text-[13px] z-10 max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
              GET IN TOUCH
            </p>
            <h1 className="text-[18px] z-10 max-w-[450px] font-bold text-white mb-3 text-center tracking-[1.12px] leading-[30px]">
              Let's get to hear from you if you have any questions.
            </h1>
            <div className="flex items-center z-10">
              <Link to="/contact">
                <Button type="outlined">Contact Us</Button>
              </Link>
            </div>
          </div>
          <div className="bg-home-doc w-full min-h-[450px] relative flex flex-col px-3 justify-center items-center">
            <p className="text-[13px] z-10 max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
              API & SDK
            </p>
            <h1 className="text-[18px] z-10 max-w-[450px] font-bold text-white mb-3 text-center tracking-[1.12px] leading-[30px]">
              You can find detailed information about our developer APIs here.
            </h1>
            <div className="flex items-center z-10">
              <Button type="outlined">Documentation</Button>
            </div>
            <div className="block md:hidden h-[100px]" />
          </div>
        </div>
      </div>
      

      <div className="w-full bg-white relative" id="faq">
        <div className="section-top" />
        <div className="section-bottom" />
        <div className="relative py-32">
          <h1 className="text-[28px] px-3 font-normal text-[#373435]  mb-3 text-center tracking-[1.4px] leading-[38px]">
            Frequently Asked Questions
          </h1>
          <p className="text-[16px] mx-auto px-3 max-w-[700px] font-normal text-[#373435] mb-14 text-center tracking-[0.56px] leading-[28px]">
            If you didn't get what you were looking for here, well, send a mail
            in.
          </p>
          <Accordion
            allowZeroExpanded={true}
            className="px-3 grid grid-cols-1 max-w-[900px] mx-auto gap-6 h-auto"
          >
            <AccordionItem className="border border-gray-200 rounded-md h-auto">
              <AccordionItemHeading>
                <AccordionItemButton>
                  How can your services benefit my business?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-[#373435]">
                  Our services are designed to streamline financial processes,
                  enhance payment experiences, optimize revenue streams, and
                  improve operational efficiency for businesses of all sizes.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="border border-gray-200 rounded-md h-auto">
              <AccordionItemHeading>
                <AccordionItemButton>
                  What industries do you serve?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-[#373435]">
                  We cater to a wide range of industries, including e-commerce,
                  retail, hospitality, travel, technology, healthcare, and more.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="border border-gray-200 rounded-md h-auto">
              <AccordionItemHeading>
                <AccordionItemButton>
                  Do you offer customizable solutions to meet specific business
                  needs?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-[#373435]">
                  No, we understand that every business is unique, but all use
                  cases must correspond with our core features.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="border border-gray-200 rounded-md h-auto">
              <AccordionItemHeading>
                <AccordionItemButton>
                  What is involved in the onboarding process for your services?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-[#373435]">
                  Our onboarding process is straightforward and efficient. We
                  work closely with clients to understand their needs after a
                  KYC setup. We also provide training and support to ensure a
                  smooth transition.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="border border-gray-200 rounded-md h-auto">
              <AccordionItemHeading>
                <AccordionItemButton>
                  How does your pricing structure work?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-[#373435]">
                  Our pricing structure is transparent and flexible, tailored to
                  the specific services and usage requirements of each client.
                  We offer competitive rates and scalable options to accommodate
                  businesses of all sizes.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="border border-gray-200 rounded-md h-auto">
              <AccordionItemHeading>
                <AccordionItemButton>
                  Can your services integrate with existing systems and
                  platforms?
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="text-[#373435]">
                  Yes, our solutions are designed to seamlessly integrate with
                  existing systems, platforms, and third-party applications to
                  ensure compatibility and facilitate smooth data exchange.
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </HomeLayout>
  );
};

export default Home;
