import { extendTheme } from "@mui/joy/styles";

export const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          "100": "#F4A85D",
        },
      },
    },
  },
  fontFamily: {
    body: "'Nunito', var(--gh-fontFamily-fallback)",
  },
});
