import { Button } from "../components/button";
import React from "react";
import { List, ListItem } from "@mui/joy";
import HomeLayout from "../layouts/homeLayout";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <HomeLayout>
      {/* HERO START */}
      <div className="w-full  bg-gradient-to-br from-[#03D17A] to-black h-[50vh] relative">
        <div className="w-full h-full bg-[#000000e5] absolute top-0 left-0 flex flex-col items-center justify-center px-3">
          <h1 className="text-[33px] max-w-[600px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
            Privacy Policy
          </h1>
          <h1 className="text-[15px] max-w-[400px] font-normal text-white mb-3 text-center tracking-[1.4px] leading-[44px] md:leading-[54px]">
            Updated: March 28, 2024
          </h1>
          <div className="h-[80px]" />
        </div>
        <div className="section-bottom" />
      </div>
      <div className="w-full bg-main relative ">
        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            INTRODUCTION
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            This Privacy Policy, also known as "the Policy," regulates how you use Akawo Synergy Limited and the procedures for collecting, 
            using, transferring, storing, and getting rid of your personal information in relation to our website <Link to="/#">(https://akawo.me)</Link>, 
            its related domains, apps, and other technological solutions (collectively, "the Platform") created by Akawo Synergy Limited 
            (hereinafter, "Akawo Synergy Limited/We/Our/Us"). Regarding the aforementioned, your rights are likewise governed by this 
            privacy policy. We take great satisfaction in protecting and upholding the privacy of the information we get from you.
          </p>
        </div>


        <div className="container my-5 mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
           WHAT PERSONAL DATA WE COLLECT
          </h1>
          <p className="text-[16px] mr-0 max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
          Any information or data that may be used to identify a specific individual is referred to as personal data. Data or information 
          that has had its identity erased is not included. The purpose of gathering the following personal data is to offer a more tailored
          and enhanced experience. You may provide us with Personal Data directly, or you may designate individuals or organizations to act 
          on your behalf. In the course of providing our services, third parties may provide us with Personal Data about you.
          </p>

          <p className="text-[16px] mr-0 max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            By using the Platform or any of our services, you consent to us collecting and processing the following Personal Data.
          </p>
          <List>
            <ListItem>
              Records of any surveys you may be asked to complete and which we keep for our research.
            </ListItem>
            <ListItem>
            Personal data that is needed to process your information as a user on the Platform, such as name, date of birth, age, phone number, email address, citizenship, religion, sex, employer's information, business information, next of kin details, and any other information.
            </ListItem>
            <ListItem>
            Data related to your income, expenses, assets, debts, account balances, payment history, National Identification Number (NIN), Bank Verification Number (BVN), credit and/or debit card information, bank account information, data page of an international passport, driver's license, permanent voter card, letter of non-indebtedness, etc.; all of this information is provided in connection with products purchased through the Platform.
            </ListItem>
            <ListItem>
              Any additional Personally Identifiable Information that you voluntarily give to US or authorize us to gather.
            </ListItem>
            
          </List>

        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
          WEBSITE COOKIES
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
          These are little text files that are downloaded to a user's computer when they visit a website. We use them to analyze user behavior in aggregate on our website. Before storing cookies, our website requests the user's consent. If the user consents, our server will only gather the data listed below:
          </p>
          <List>
            <ListItem>
            The IP address of the visitor (including, if reverse lookup is used, the domain name connected to the IP address).

            </ListItem>

            <ListItem>
            The time and date of the website visit.
            </ListItem>

            <ListItem>
            The web pages that were seen.
            </ListItem>

            <ListItem>
              The open web browser.
            </ListItem>
          </List>

          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
          Additionally, Akawo Synergy Limited will gather the following when available:
          </p>
          <List>
            <ListItem>
            The nation from which the website visitor is from.

            </ListItem>

            <ListItem>
            The website that the visitor is using to reach the website of Akawo Synergy Limited.
            </ListItem>

            <ListItem>
            The operating system and connection type.
            </ListItem>
          </List>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
          CONTACT DETAILS
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
          First and last name, phone number, email address, and any further data needed to handle your information as a platform user.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
          MOBILE DEVICES
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
          We gather user data from mobile applications and websites that are tailored for mobile usage, which are used to access certain of our applications. The use of mobile devices for data access and usage is covered by this policy.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            QUESTIONS
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            We utilize the personal information you provide on our contact form to respond to your inquiries when you submit them to us. Any personal information we collect from you won't be shared or used for any other reason without first getting your permission.
          </p>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            You may provide us with Personal Data directly, or you may designate individuals or organizations to act on your behalf. In 
            conjunction with our Services, third parties (such credit reporting agencies) may provide us with Personal Data about you.
            Without your permission, we will not gather any personal information about you. 
            Where personal data must be collected by law, or under the terms of a contract we have with you and you fail to provide that 
            data when requested, we may not be able to provide the Services envisaged or requested.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            CONSENT
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            When you register or use any of our websites, linked sites, portals, applications, or services, you agree to the terms and conditions of this policy, which is then put into effect. You consent to the collection and use of your Personal Data in line with this Policy if you decide to use the Platform. We may utilize the personal data gathered to enhance the Platform's user experience for our customers. Your personal information won't be used or disclosed to third parties unless permitted by this policy.
          </p>
        </div>



        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            COLLECTION OF PERSONAL DATA AND USE
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            We use Personal Data held about you in the following ways;
          </p>
          <List>
            <ListItem>
              To fulfill any duty resulting from and to assert our rights under any contract that we may have with you.
            </ListItem>

            <ListItem>
              To inform you of any modifications to our services
            </ListItem>

            <ListItem>
              To review and evaluate your application for a job with us.
            </ListItem>

            <ListItem>
              For market research and statistical analysis
            </ListItem>

            <ListItem>
              To create and enhance our offerings
            </ListItem>

            <ListItem>
              To make changes to our documentation
            </ListItem>

            <ListItem>
              To fulfill a legitimate interest (that your data protection interest does not supersede).
            </ListItem>

            <ListItem>
              To fulfill a duty imposed by law or regulation
            </ListItem>

            <ListItem>
              To control risk, identify, stop, or correct fraud or other potentially unlawful or forbidden services.
            </ListItem>

            <ListItem>
              For any other reason you have given your express approval to
            </ListItem>
          </List>
        </div>



        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            USAGE OF PERSONAL DATA
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            US data collection and usage are governed by a number of principles. According to these guidelines, personal information should:
          </p>
          <List>
            <ListItem>
              Be handled transparently, legally, and equitably.
            </ListItem>

            <ListItem>
              Must be acquired for a specific, legitimate purpose, and they cannot be handled in a way that conflicts with those goals.
            </ListItem>

            <ListItem>
              Should be sufficient, pertinent, and restricted to what is required to achieve the processing goal.
            </ListItem>

            <ListItem>
              Be precise and, if need, current.
            </ListItem>

            <ListItem>
              Not be retained for any longer than is required in order to process it.
            </ListItem>

            <ListItem>
              Be protected, by appropriate technological and organizational safeguards, from unapproved processing and unintentional loss, damage, or destruction.
            </ListItem>


            <ListItem>
              Be handled in a way that respects the rights of the data subject.
            </ListItem>

            
          </List>
        </div>



        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            HOW MUCH TIME DO WE KEEP YOUR PERSONAL DATA?
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Unless you request that your personal data be removed in accordance with this Policy, we will keep your personal data as long as you are an active user of the Platform. Please be aware that in cases where there is a legal necessity, your personal data may be kept for a longer amount of time notwithstanding your request to have it removed.
          </p>

          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            When a third party you have subscribed to services from on the Platform receives your Personal Data, the third party's relevant policy on the retention of user data may also apply to the Personal Information maintained by that third party.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            HYPERLINK TO OTHER SCHEMES
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Please be aware that connections to external websites that are not run or under Our control may be present in the services offered via the Platform. We do not conduct investigations, monitor, or verify the authenticity or completeness of such websites; thus, we are not liable for the information presented on them, their viewpoints, their privacy policies, or the practices of any third-party websites or services. Please keep in mind that our Privacy and Cookies Policy is no longer in force when you utilize a link to leave any of our services and visit another website. Any other website you visit or interact with through a link from our site is governed by the rules and policies of that particular website. Third parties like this might utilize their own cookies or other methods to collect information about you. Therefore, we strongly advise you to review the privacy policy of these websites.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            YOUR RIGHTS WITH RESPECT TO YOUR PERSONAL DATA
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
          You have the following rights in relation to your Personal Data held with us;
          </p>

          <List>
            <ListItem>
              To view the personal information we have on you.
            </ListItem>

            <ListItem>
              To have any incomplete or inaccurate personal data updated.
            </ListItem>

            <ListItem>
              At any moment, to choose not to receive commercial messages.
            </ListItem>

            <ListItem>
              To seek the erasure of personal data or to restrict or object to its processing (according to relevant legislation)
            </ListItem>

            <ListItem>
              To obtain a copy of the Personal Data you have given Us in a machine-readable, generally used, structured format (depending on the situation and the relevant laws).
            </ListItem>

            <ListItem>
              The ability to revoke your permission in cases where you freely supplied personal data or otherwise gave your OK for its use.
            </ListItem>


            <ListItem>
              The option to lodge a complaint with an authority for data protection (see the "Complaints" section).
            </ListItem>

            
          </List>
        </div>



        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            STORAGE METHOD OF YOUR PERSONAL DATA?
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Your Personal Data is processed and stored by us on our cloud-based network of computers. Different nations have different data protection regulations; some offer more protection than others. We implement the same safeguards outlined in this Policy wherever your information is processed. You have the right to know about the suitable data protection measures in the foreign nation, upon request.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
           WHOM DO WE DISCLOSE YOUR PERSONAL INFO TO?
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Your personally identifiable information is never rented or sold to other people or businesses. You consent to our sharing of your Personal Data with the third parties listed in the table. below.
          </p>

          <List>
            <ListItem>
              Affiliates: In order to assist us in creating, maintaining, and offering the services via the Platform, our Affiliates may have access to your Personal Data.
            </ListItem>

            <ListItem>
              Service Providers: These companies help us with our services, such as developing and maintaining websites, hosting, upkeep, backups, storage, virtual infrastructure, payment processing, and other services that might need them to access or use your personal data. You should be aware that when we disclose your personal data to business partners, those parties may have retained personal data about you obtained through other channels, such as previous interactions.
            </ListItem>

            <ListItem>
              Professional Advisers: In order to offer consulting, compliance, legal, accounting, and related services, our attorneys, accountants, and auditors may need to check your personal data.
            </ListItem>

            <ListItem>
              Regulatory and Legal Authority: If we think it is reasonably required to abide by a law, rule, order, subpoena, or audit, or to safeguard the safety of any person, to address fraud, security, or technical concerns, we may share your Personal Data.
            </ListItem>

            <ListItem>
              Advertisers: We may grant access to your Personal Data to third parties, such as ad-servers or ad-networks, so they may display advertisements on the Platform that are specific to your interests.
            </ListItem>

            <ListItem>
              Application Program Interface (API) Users: Certain partners, such as any other aggregator, funder, or credit bureau agency, may be authorized access to our API documentation, allowing them to utilize a restricted section of the platform through API access.
            </ListItem>

            <ListItem>
              Additional parties: Be aware that your Personal Data may be accessible to our highest level employees, approved workers who are obliged to have access to the Platform as part of their job with Us, and investors. Without your permission or prior notification, we may transfer your Personal Data as part of the transferred asset in the case of a corporate sale, merger, reorganization, dissolution, or other comparable event.
            </ListItem>
            
          </List>

          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Additionally, if required by law or to defend our interests or the interests of the group in court, we may disclose your personal information to the aforementioned parties as well as other third parties.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            PERSONAL DATA TRANSFERRED TO THIRD COUNTRIES
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            We occasionally collaborate with other tech suppliers. Personal data may be transferred, as a result, to an international organization or another nation. Only vendors that have Standard Contractual Clauses in place with us are allowed to cooperate with Akawo Synergy Limited, as this will provide an identical degree of security for such transfers in compliance with the NDPR.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            SECURITY
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            We appreciate that you have trusted us with your personal information in relation to your use of the Platform, and we will make every effort to secure it using reasonably priced methods. We do not, however, promise complete security.
          </p>
        </div>


        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            CHILDREN’S PRIVACY
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            We do not use the Platform to knowingly solicit data from children or individuals under the age of Fifteen (15). Where a parent or guardian discovers that a child or individual under the age of Fifteen (15) has provided us with his/her Personal Data without their consent, he or she should contact us at <Link to="mailto:help@akawo.me">help@akawo.me</Link>, <Link to="mailto:support@puplar.com">support@puplar.com</Link> and we shall immediately delete the user’s account from our servers.
          </p>
        </div>

        
        <div className="container mx-auto h-full px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            CHANGES TO THIS STATEMENT
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            This Policy may be updated periodically without your agreement or prior notification. As a result, it is recommended that you often check this Platform for updates. If there are any updates, we'll post the updated Policy on this platform to let you know. Please be aware that modifications will take effect as soon as they are posted on this platform.
          </p>
        </div>


        <div className="container mx-auto h-full mb-10  px-2">
          <h1 className="text-[20px] mx-auto font-normal text-[#373435] text-left tracking-[1.4px] mb-0 leading-[42px]">
            COMPLAINTS
          </h1>
          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            You can contact the Data Protection Officer at <Link to="mailto:help@akawo.me">help@akawo.me</Link> if you have any concerns about an alleged violation of her regulations by us. The Data Protection Officer will look into your complaint and let you know how it's being addressed.
          </p>

          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Please be aware that you have the option to file a complaint with the appropriate data protection authority if your concerns are not adequately resolved. Speak with us.
          </p>

          <p className="text-[16px] max-w-[1300px] font-normal text-[#373435] text-left tracking-[0.56px] leading-[28px]">
            Please email us at <Link to="mailto:help@akawo.me">help@akawo.me</Link> if you have any questions or recommendations regarding our policy.
          </p>
        </div>
















      </div>
      
    </HomeLayout>
  );
};

export default Privacy;
